import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FantasyLeaderboardQuery, FantasyLeaderboard, FantasyLeaderboardService, PopulationQuery } from '@fgb/core';
import { Subscription, combineLatest } from 'rxjs';
import * as _moment from 'moment';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { Populations } from 'src/app/shared/utilities/populations';

const moment = _moment;
@Component({
  selector: 'fgb-fantasy-leaderboard-page',
  templateUrl: './fantasy-leaderboard-page.component.html',
  styleUrls: ['./fantasy-leaderboard-page.component.scss'],
})
export class FantasyLeaderboardPageComponent implements OnInit, OnDestroy {
  @Input() backButtonLink: string = '/games/pickaplayer';

  yearlyLeaderboards: FantasyLeaderboard[];
  monthlyLeaderboard: any[] = [];
  fantasySub: Subscription;

  showMonthly: boolean = true;
  year: number = new Date().getFullYear();
  isCorporate: boolean;
  populations = Populations;

  customOptions: OwlOptions = {
    items: 1,
    margin: OwlCarouselSettings.Margin,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    nav: true,
    dots: false,
    startPosition: new Date().getMonth(),
    navText: ['<span class="material-icons material-symbols-outlined">chevron_left</span>', '<span class="material-icons material-symbols-outlined">chevron_right</span>'],
    stagePadding: 0,
  };

  constructor(
    private fantasyLeaderboardQuery: FantasyLeaderboardQuery,
    private fantasyLeaderboardService: FantasyLeaderboardService,
    private populationQuery: PopulationQuery
  ) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.fantasySub = combineLatest([
      this.fantasyLeaderboardService.fetchMonthlyLeaderboard(10),
      this.fantasyLeaderboardService.fetchYearlyLeaderboard(10),
    ]).subscribe((x) => {
      let monthly = this.fantasyLeaderboardQuery.getMonthlyLeaderboard() as FantasyLeaderboard[];
      this.yearlyLeaderboards = this.fantasyLeaderboardQuery.getYearlyLeaderboard();

      let today = new Date();
      let currentMonth = 7;
      let currentYear = today.getFullYear() - 1;

      for (let index = 1; index <= 12; index++) {
        currentMonth++;
        if (currentMonth > 12) {
          currentMonth = 1;
          currentYear = today.getFullYear();
        }

        if (currentYear == today.getFullYear() && currentMonth > today.getMonth() + 1) {
          this.monthlyLeaderboard.push({
            month: moment.monthsShort(currentMonth - 1) + ' ' + currentYear,
            entries: [],
          });
        } else {
          this.monthlyLeaderboard.push({
            month: moment.monthsShort(currentMonth - 1) + ' ' + currentYear,
            entries: monthly.filter((x: FantasyLeaderboard) => {
              return (x.FantasyMonth as unknown as number) === currentMonth;
            }),
          });
        }
        this.customOptions.startPosition = this.monthlyLeaderboard.length - 1
      }
    });
  }

  ngOnDestroy() {
    if (this.fantasySub) {
      this.fantasySub.unsubscribe();
    }
  }
}
