<div class="d-lg-block d-none">
  <fgb-banner [title]="'fantasy.page.banner' | translate"></fgb-banner>
</div>

<div class="container">
  <div class="pick-a-player-bar d-flex d-lg-none">
    <a [routerLink]="[backButtonLink]" class="btn d-flex align-items-center font-sans-bold px-0">
      <span class="arrow-right material-icons text-danger"> chevron_left </span>
      <span class="font-weight-bold font-size-14 text-danger">{{ 'pick.player.back.button' | translate }}</span>
    </a>
  </div>
  <div class="row">
    <div class="col-lg-4 col-12 py-lg-3 py-2">
      <fgb-fantasy-game-panel
        [game]="game$ | async"
        (button)="confirmSelection()"
        [buttonState]="button"
        [horizontal]="false"
      ></fgb-fantasy-game-panel>
    </div>
    <div class="col-lg-8 col-12">
      <div>
        <div class="row">
          <div class="col-lg-3 col-6" *ngFor="let player of players$ | async">
            <div class="card rounded overflow-hidden mt-3 border-disabled" (click)="select(player.PlayerId)">
              <img class="card-img-top w-100" src="{{ player.Image | contentImage : '3' }}" />
              <div
                class="card-body d-flex justify-content-start align-items-center border-disabled-top"
                [ngClass]="{ 
                  'bg-primary text-active': player.IsSelected && !isCorporate,
                  'text-black bg-white': !player.IsSelected,
                  'bg-primary-corporate text-white': player.IsSelected && isCorporate }"
              >
                <div class="mx-auto mb-0 font-size-12 player-name">{{ player.PlayerName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-block d-lg-none container">
        <div class="row">
          <div class="col-12 card border-0 shadow rounded overflow-hidden my-2" *ngFor="let player of players$ | async">
            <div class="row" (click)="select(player.PlayerId)">
              <div
                class="col-2 ps-0 pe-0"
                [ngClass]="{ 'bg-primary text-white': player.IsSelected, 'text-black bg-white': !player.IsSelected }"
              >
                <img class="w-100" src="{{ player.Image | contentImage : '3' }}" />
              </div>
              <div
                class="col-10 d-flex align-items-center justify-content-center"
                [ngClass]="{ 'bg-primary text-white': player.IsSelected, 'text-black bg-white': !player.IsSelected }"
              >
                <div class="ms-1 mb-0 font-size-12 text-uppercase player-name">{{ player.PlayerName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="d-none d-lg-block">
    <a [routerLink]="[backButtonLink]" class="btn d-flex align-items-center font-sans-bold px-0">
      <span class="arrow-right material-icons text-danger"> chevron_left </span>
      <span class="font-weight-bold font-size-14 text-danger">{{ 'pick.player.back.button' | translate }}</span>
    </a>
  </div>
</div>
