<div class="fantasy-panel rounded overflow-hidden">
  <div class="row mx-0">
    <div class="col-lg-7 px-0 bg-coming-soon">
      <div class="game-summary h-100">
        <div class="d-none d-lg-flex h-100 justify-content-between flex-column">
          <div class="text-center line-height-18 text-info">
            <p class="mb-1 font-size-12">{{ 'pick.player.next.game.title' | translate }}</p>
            <p class="font-size-14 font-sans-bold mb-0">{{ 'pick.player.next.game.description' | translate }}</p>
          </div>
          <div class="row team-vs-team d-flex justify-content-center align-items-center mx-0">
            <div class="team home col-4">
              <img
                *ngIf="game.HomeTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.HomeTeamLogo | contentImage : '6' }}"
              />
            </div>
            <div class="versus col-4 text-center">
              <div class="font-size-30 font-default text-white">{{ 'game.versus' | translate}}</div>
            </div>
            <div class="team away col-4">
              <img
                *ngIf="game.AwayTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.AwayTeamLogo | contentImage : '6' }}"
              />
            </div>
          </div>
          <div class="line-height-18 font-size-12 text-center">
            <div class="text-info">{{ gameDate }} | {{ gameTime }}</div>
          </div>
        </div>
        <div class="d-block d-lg-none block-coming-soon">
          <div class="row">
            <div class="col-4 line-height-18">
              <p class="mb-lg-0 mb-1 font-size-12 mt-lg-0 mt-1">{{ 'pick.player.next.game.title' | translate }}</p>
            </div>
            <div class="col-8">
              <div class="row d-flex justify-content-center align-items-center mx-0">
                <div class="team home col-4">
                  <img *ngIf="game.HomeTeamLogo" class="d-flex mx-auto" src="{{ game.HomeTeamLogo | contentImage : '6' }}" />
                </div>
                <div class="versus col-4 text-center">
                  <div class="font-size-30 font-default text-white">{{ 'game.versus' | translate}}</div>
                </div>
                <div class="team away col-4">
                  <img *ngIf="game.AwayTeamLogo" class="d-flex mx-auto" src="{{ game.AwayTeamLogo | contentImage : '6' }}" />
                </div>
              </div>
              <div class="line-height-18 font-size-12 text-center">
                <div class="dates mt-1">{{ gameDate }} | {{ gameTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 py-2 px-3">
      <div class="d-none d-lg-block">
        <div class="player-select text-center">
          <div class="player-image mx-auto rounded-circle overflow-hidden">
            <img src="assets/images/no-player-image.png" class="w-100 h-100 m-auto" />
          </div>
          <div class="question-text text-black font-sans-bold font-size-14">
            {{ 'pick.player.next.game.no.player' | translate }}
          </div>
          <button class="btn btn-upcoming btn-select font-sans-bold w-100 mb-2 text-white" [disabled]="true">
            {{ 'pick.player.next.game.select.button' | translate }}
          </button>

          <fgb-fantasy-player-select-confirm [isGamePending]="true" [game]="game"> </fgb-fantasy-player-select-confirm>
        </div>
      </div>
      <div class="d-block d-lg-none">
        <div class="player-select text-center">
          <div class="row">
            <div class="col-12">
              <div class="question-text font-weight-bold font-size-12 m-3">
                {{ 'pick.player.next.game.no.player' | translate }}
              </div>
              <button class="btn text-white btn-info btn-select font-sans-bold mb-2" [disabled]="true">
                {{ 'pick.player.next.game.select.button' | translate }}
              </button>
              <fgb-fantasy-player-select-confirm [isGamePending]="true" [game]="game"> </fgb-fantasy-player-select-confirm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
