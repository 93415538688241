<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div
    class="mobile-nav-bar d-flex justify-content-between"
    [ngClass]="isCorporate ? 'bg-primary-corporate mobile-navbar-corporate' : 'bg-primary mobile-navbar-default'"
  >
    <a class="nav-item font-weight-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <ng-container *ngIf="!isCorporate && !isJuniorBluebirds"
        ><img class="home-logo" src="assets/images/logo.svg" draggable="false"
      /></ng-container>
      <ng-container *ngIf="isCorporate"
        ><img class="home-logo" src="assets/images/corporate-logo.png" draggable="false"
      /></ng-container>
      <ng-container *ngIf="isJuniorBluebirds"
        ><img class="home-logo" src="assets/images/kids-logo-v2.png" draggable="false"
      /></ng-container>
    </a>
    <a class="nav-item" [routerLink]="['/rewards']">
      <span class="icon material-icons material-mobile-dropdown">redeem</span>
    </a>
    <a class="nav-item" [routerLink]="['/games/quiz']">
      <span class="icon material-icons material-mobile-dropdown">extension</span>
    </a>
    <a class="nav-item">
      <fgb-notification-button></fgb-notification-button>
    </a>
    <button class="nav-item font-sans-bold navbar-toggler m-0 px-2" (click)="isCollapsed = !isCollapsed">
      <span class="icon material-icons material-mobile-nav hamburger-icon">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div
    [ngbCollapse]="isCollapsed"
    class="mobile-nav-menu-dropdown"
    [ngClass]="isCorporate ? 'bg-primary-corporate' : 'bg-primary'"
  >
    <div class="d-flex flex-column p-3 mobile-nav-menu-content">
      <!-- Club Logo -->
      <div class="d-flex align-items-center mt-2 mb-2 justify-content-between">
        <ng-container *ngIf="!isCorporate && !isJuniorBluebirds"
          ><img class="brand" src="assets/images/logo.svg" draggable="false" (click)="navigateToHome()"
        /></ng-container>
        <ng-container *ngIf="isCorporate"
          ><img class="brand" src="assets/images/corporate-logo.png" draggable="false" (click)="navigateToHome()"
        /></ng-container>
        <ng-container *ngIf="isJuniorBluebirds"
          ><img class="brand" src="assets/images/kids-logo-v2.png" draggable="false" (click)="navigateToHome()"
        /></ng-container>
        <button class="close-button border-0 p-0" (click)="isCollapsed = !isCollapsed">
          <span class="icon material-icons cancel text-white"> close </span>
        </button>
      </div>
      <!-- Account Details -->
      <div class="text my-2 mb-3 pb-3 border-bottom" *ngIf="memberDetails$ | async as memberDetails">
        <h4 class="text-white text-uppercase mb-0 member-name">{{ memberDetails.FirstName }}</h4>
        <ng-container *ngIf="memberCard$ | async as memberCard">
          <div class="d-flex font-size-12 text-white mt-2">
            <div *notInPopulation="populations.JuniorBluebirds" class="border-right">
              {{ 'member.card.product.code' | translate }}
            </div>
            <div *inPopulation="populations.JuniorBluebirds" class="border-right">
              {{ 'member.card.product.code.kid' | translate }}
            </div>
            <div>&nbsp;{{ memberCard.MemberId }}</div>
          </div>
        </ng-container>
      </div>
      <div class="mb-3 pb-3 border-bottom" *notInPopulation="[populations.JuniorBluebirds]">
        <div class="row">
          <div class="d-flex align-items-center w-100">
            <div class="col-6">
              <div class="card-account-no text-start text-white pe-5" *ngIf="loyaltyPointsCard$">
                <label class="mb-0">{{ 'member.card.points.balance' | translate }}</label>
                <div class="font-size-14">{{ loyaltyPointsCard$ | async | number : '1.0' : 'en-GB' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-nav-button-container">
        <!-- Nav Buttons -->
        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          [routerLink]="['/account']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="icon material-icons material-mobile-dropdown">person</span>
          <div class="nav-text">{{ 'navbar.account' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>
        <ng-container *notInPopulation="[populations.Free]">
          <a
            [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
            [routerLink]="['/rewards/wallet']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
            *notInPopulation="[populations.Free]"
          >
            <span class="icon material-icons material-mobile-dropdown">account_balance_wallet</span>
            <div class="nav-text">{{ 'navbar.rewards.history' | translate }}</div>
            <span class="arrow-right material-icons ms-auto"> chevron_right </span>
          </a>
        </ng-container>
        <a
          class="nav-item"
          [routerLink]="['/challenges']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="icon material-icons material-mobile-dropdown">flag</span>
          <div class="nav-text fw-bold">{{ 'nav.challenges' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>

        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.savings'">
          <a
            class="nav-item"
            [routerLink]="['/savings']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
            *notInPopulation="[populations.JuniorBluebirds]"
          >
            <span class="icon material-icons material-mobile-dropdown">sell</span>
            <div class="nav-text fw-bold">{{ 'nav.saving' | translate }}</div>
            <span class="arrow-right material-icons ms-auto"> chevron_right </span>
          </a>
        </ng-container>

        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          [routerLink]="['/rewards']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="icon material-icons material-mobile-dropdown">redeem</span>
          <div class="nav-text">{{ 'navbar.rewards' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>
        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          [routerLink]="['/worksheet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          *inPopulation="[populations.JuniorBluebirds]"
        >
          <span class="icon material-icons-outlined material-mobile-dropdown">draw</span>
          <div class="nav-text">{{ 'navbar.activities' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>
        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          [routerLink]="['/games/quiz']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="icon material-icons material-mobile-dropdown">extension</span>
          <div class="nav-text">{{ 'navbar.games' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>
        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.gallery'">
          <a
            [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
            [routerLink]="['/gallery']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
            *notInPopulation="[populations.Free]"
          >
            <span class="icon material-icons material-mobile-dropdown">photo_library</span>
            <div class="nav-text">{{ 'navbar.gallery' | translate }}</div>
            <span class="arrow-right material-icons ms-auto"> chevron_right </span>
          </a>
        </ng-container>
        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          [routerLink]="['/earn']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          *notInPopulation="[populations.JuniorBluebirds]"
        >
          <span class="icon material-icons material-mobile-dropdown">emoji_events</span>
          <div class="nav-text">{{ 'navbar.earnpoints' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>
        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          href="https://www.cardiffcityfc.co.uk/?utm_source=rewards&utm_medium=referral&utm_campaign=rewards_general"
          target="_blank"
        >
          <img
            alt=""
            class="logo-nav icon material-icons material-mobile-dropdown"
            src="assets/images/logo.svg"
            draggable="false"
          />
          <div class="nav-text">{{ 'navbar.cardiff.website' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>
        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          href="https://www.cardiffcityfcstore.com/?utm_source=rewards&utm_medium=referral&utm_campaign=rewards_general"
          target="_blank"
        >
          <span class="icon material-icons material-mobile-dropdown">shopping_cart</span>
          <div class="nav-text">{{ 'navbar.cardiff.store' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>
        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          href="https://www.cardiffcityfc.co.uk/cardiff-city-tv?utm_source=rewards&utm_medium=referral&utm_campaign=rewards_general"
          target="_blank"
          *notInPopulation="[populations.Free]"
        >
          <span class="icon material-icons material-mobile-dropdown">smart_display</span>
          <div class="nav-text">{{ 'navbar.cardiff.tv' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>
        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          [routerLink]="['/contact']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="icon material-icons material-mobile-dropdown">email</span>
          <div class="nav-text">{{ 'navbar.contact.us' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>
        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          [routerLink]="['/terms']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="icon material-icons material-mobile-dropdown">article</span>
          <div class="nav-text">{{ 'navbar.tnc' | translate }}</div>
          <span class="arrow-right material-icons ms-auto"> chevron_right </span>
        </a>

        <a
          [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'"
          [routerLink]="['/faq']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="icon material-icons material-mobile-dropdown">help</span>
          <div class="nav-text">{{ 'navbar.faqs' | translate }}</div>
        </a>

        <!-- Logout Button -->

        <a [ngClass]="isCorporate ? 'corporate-nav-item' : 'nav-item'" (click)="logout()">
          <span class="icon material-icons material-mobile-dropdown">power_settings_new</span>
          <div class="nav-text">{{ 'navbar.logout' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
