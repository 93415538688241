import { Component, OnInit, Input } from '@angular/core';
import { FantasyHistory, FantasyGame, GameStatus, PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'fgb-fantasy-history-list-item',
  templateUrl: './fantasy-history-list-item.component.html',
  styleUrls: ['./fantasy-history-list-item.component.scss'],
})
export class FantasyHistoryListItemComponent implements OnInit {
  @Input() game: FantasyGame;
  @Input() fantasyHistoryItem: FantasyHistory;

  gameDate: string;
  gameTime: string;
  isPending: boolean = false;
  
  populations = Populations;
  isCorporate: boolean;

  constructor( private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.gameDate = moment.utc(this.fantasyHistoryItem.GameDate).format('DD MMM');
    this.gameTime = moment.utc(this.fantasyHistoryItem.GameDate).format('HH:mm');

    this.isPending = this.fantasyHistoryItem.Status === GameStatus.Ready;
  }
}
