<fgb-banner [title]="'quiz.page.banner' | translate"></fgb-banner>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="quizzes$ | async as quizzes">
    <div *ngIf="!quizzes.length">
      <div class="no-items w-100 text-center">
        <div class="icon material-icons text-grey">live_help</div>
        <h5
          class="fw-bold text-uppercase"
          [ngClass]="isCorporate ? 'text-secondary-corporate corporate-font' : 'text-primary default-font'"
        >
          {{ 'trivia.placeholder.title' | translate }}
        </h5>
        <div class="no-results-placeholder-text text-info mb-5">{{ 'trivia.placeholder.description' | translate }}</div>
      </div>
    </div>
    <div *ngFor="let quiz of quizzes">
      <fgb-survey-item [survey]="quiz"></fgb-survey-item>
    </div>
  </ng-container>
</div>
