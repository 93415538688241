<!--Banner-->
<fgb-banner [title]="'home.page.banner' | translate" class="d-none d-lg-block"></fgb-banner>

<!--Member Card-->
<div class="d-block d-lg-none">
  <div class="container py-3">
    <fgb-barcode></fgb-barcode>
  </div>
</div>
 
<!--Scorecards carousel-->
<ng-container *notInPopulation="[populations.Free]">
  <div>
    <fgbcl-scorecard-carousel [showAllView]="true" [phaseThree]="true" icon="chevron_right" [ngClass]="{'corporate': isCorporate}"></fgbcl-scorecard-carousel>
  </div>
</ng-container>

<!--Vouchers-->
<div class="container my-4">
  <fgb-vouchers></fgb-vouchers>
</div>

<!-- Featured Rewards -->
<ng-container *ngIf="mixedLoyaltyItems$ | async as mixedLoyaltyItems">
  <div *ngIf="mixedLoyaltyItems.length > 0">
    <div class="featured-rewards-container pb-2">
      <div class="container">
        <fgbcl-featured-rewards [ngClass]="{'corporate': isCorporate, 'junior': isJuniorBluebird}"></fgbcl-featured-rewards>
      </div>
    </div>
  </div>
</ng-container>

<!-- Announcements -->
<div class="container">
  <fgbcl-announcements></fgbcl-announcements>
</div>


<!-- Activities-->
<!-- <ng-container *ngIf="hasWorksheets$ | async as has">
  <div class="activities-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ng-container *inPopulation="[populations.JuniorBluebirds]">
            <div class="d-flex justify-content-between align-items-center mt-4">
              <h5 class="text-white worksheet-title">{{ 'worksheet.kid.title' | translate }}</h5>
              <div class="d-flex align-items-center cursor-pointer text-white" [routerLink]="['/worksheet']">
                <span class="font-size-14 fw-bold"> Show All </span>
                <span class="icon material-icons ps-1 fw-bold font-size-14" aria-hidden="true">arrow_forward_ios</span>
              </div>
            </div>
          </ng-container>
          <ng-container *notInPopulation="[populations.JuniorBluebirds]">
            <h5 class="text-white worksheet-title mt-4">{{ 'worksheet.title' | translate }}</h5>
          </ng-container>
          <fgb-worksheet-list-carousel></fgb-worksheet-list-carousel>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->

<ng-container
  *ngIf="{
    polls: polls$ | async
  } as surveys"
>
  <!-- <ng-container *ngIf="!!surveys.polls?.length">
    <ng-container *ngIf="surveys.polls && !!surveys.polls.length">
      <div class="home-poll container p-0">
        <div class="title d-flex align-items-center justify-content-between mt-4">
          <h4 class="text-start featured-polls">{{ 'featured.games.page.polls.title' | translate }}</h4>
          <div class="d-flex align-items-center cursor-pointer text-black" [routerLink]="['/games/polls']">
            <span class="font-size-14 fw-bold"> Show All </span>
            <span class="icon material-icons ps-1 fw-bold font-size-14" aria-hidden="true">arrow_forward_ios</span>
          </div>
        </div>

        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let poll of surveys.polls">
            <ng-template carouselSlide>
              <fgb-survey-item [survey]="poll"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </ng-container>
  </ng-container>
</ng-container> -->

<!-- Upcoming Games -->
<!-- <ng-container *notInPopulation="[populations.JuniorBluebirds]">
  <fgb-game-list></fgb-game-list>
-->
  <div class="container pt-3">
    <div class="text-center mt-3">
      <a href="https://www.facebook.com/cardiffcityfc" target="_blank" class="px-2">
        <img
          class="social-media-logo"
          src="assets/images/social-media-icons/facebook.svg"
          draggable="false" *notInPopulation="populations.Hospitality"
        />
        <img
          class="social-media-logo"
          src="assets/images/social-media-icons/facebook-corporate.svg"
          draggable="false" *inPopulation="populations.Hospitality"
        />
      </a>
      <a href="https://www.instagram.com/cardiffcityfc/" target="_blank" class="px-2">
        <img
          class="social-media-logo"
          src="assets/images/social-media-icons/instagram.svg"
          draggable="false" *notInPopulation="populations.Hospitality"
        />
        <img
        class="social-media-logo"
        src="assets/images/social-media-icons/instagram-corporate.svg"
        draggable="false" *inPopulation="populations.Hospitality"
        />
      </a>
      <a href="https://twitter.com/CardiffCityFC" target="_blank" class="px-2">
        <img
          class="social-media-logo"
          src="assets/images/social-media-icons/x.png"
          draggable="false" *notInPopulation="populations.Hospitality"
        />
        <img
        class="social-media-logo"
        src="assets/images/social-media-icons/x-corporate.svg"
        draggable="false" *inPopulation="populations.Hospitality"
        />
      </a>
      <a href="https://www.youtube.com/channel/UCfBVy8PAMwyNbac6D0Mk8gQ" target="_blank" class="px-2">
        <img
          class="social-media-logo"
          src="assets/images/social-media-icons/youtube.svg"
          draggable="false" *notInPopulation="populations.Hospitality"
        />
        <img
        class="social-media-logo"
        src="assets/images/social-media-icons/youtube-corporate.svg"
        draggable="false" *inPopulation="populations.Hospitality"
        />
      </a>
      <a href="https://www.threads.net/@cardiffcityfc" target="_blank" class="px-2">
        <img
          class="social-media-logo"
          src="assets/images/social-media-icons/threads.svg"
          draggable="false" *notInPopulation="populations.Hospitality"
        />
        <img
        class="social-media-logo"
        src="assets/images/social-media-icons/threads-corporate.svg"
        draggable="false" *inPopulation="populations.Hospitality"
        />
      </a>
      <a href="https://www.tiktok.com/@cardiffcityfc" target="_blank" class="px-2">
        <img
          class="social-media-logo"
          src="assets/images/social-media-icons/tiktok.svg"
          draggable="false" *notInPopulation="populations.Hospitality"
        />
        <img
        class="social-media-logo"
        src="assets/images/social-media-icons/tiktok-corporate.svg"
        draggable="false" *inPopulation="populations.Hospitality"
        />
      </a>
    </div>
  </div>
