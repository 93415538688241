import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  FantasyGameQuery,
  FantasyGame,
  FantasyLeaderboardQuery,
  FantasyLeaderboardService,
  FantasyLeaderboard,
  PopulationQuery,
} from '@fgb/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { Populations } from 'src/app/shared/utilities/populations';

const moment = _moment;

@Component({
  selector: 'fgb-fantasy-games-page',
  templateUrl: './fantasy-games-page.component.html',
  styleUrls: ['./fantasy-games-page.component.scss'],
})
export class FantasyGamesPageComponent implements OnInit, OnDestroy {
  currentGames$: Observable<FantasyGame[]>;
  pendingGames$: Observable<FantasyGame[]>;
  pendingPeriod: number = 48;

  yearlyLeaderboards: FantasyLeaderboard[];
  monthlyLeaderboard: any[] = [];

  showMonthly: boolean = true;
  year: number = new Date().getFullYear();

  populations = Populations;
  isCorporate: boolean;
  collapsedFirst: boolean = false;
  collapsedSecond: boolean = false;
  collapsedThird: boolean = false;

  customOptions: OwlOptions = {
    items: 1,
    margin: OwlCarouselSettings.Margin,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    center: false,
    nav: true,
    dots: false,
    startPosition: new Date().getMonth(),
    navText: ['<span class="material-icons material-symbols-outlined">chevron_left</span>', '<span class="material-icons material-symbols-outlined">chevron_right</span>'],
    stagePadding: 0,
  };

  customOptions2: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    navSpeed: 2000,
    responsive: {
      0: { items: 1, autoWidth: false },
      740: { items: 1, autoWidth: true, autoHeight: false },
    },
  };

  fantasySub: Subscription;

  constructor(
    private fantasyQuery: FantasyGameQuery,
    private router: Router,
    private fantasyLeaderboardService: FantasyLeaderboardService,
    private fantasyLeaderboardQuery: FantasyLeaderboardQuery,
    private populationQuery: PopulationQuery,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentGames$ = this.fantasyQuery.selectCurrentGames();
    this.pendingGames$ = this.fantasyQuery.selectFinshedFantasyGamesAfterDate(moment.utc().subtract(this.pendingPeriod, 'hours'));
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.fantasySub = combineLatest([
      this.fantasyLeaderboardService.fetchMonthlyLeaderboard(3),
      this.fantasyLeaderboardService.fetchYearlyLeaderboard(3),
    ]).subscribe((x) => {
      let monthly = this.fantasyLeaderboardQuery.getMonthlyLeaderboard() as FantasyLeaderboard[];
      this.yearlyLeaderboards = this.fantasyLeaderboardQuery.getYearlyLeaderboard();

      let today = new Date();
      let currentMonth = 7;
      let currentYear = today.getFullYear() - 1;

      for (let index = 1; index <= 12; index++) {
        currentMonth++;
        if (currentMonth > 12) {
          currentMonth = 1;
          currentYear = today.getFullYear();
        }

        if (currentYear == today.getFullYear() && currentMonth > today.getMonth() + 1) {
          this.monthlyLeaderboard.push({
            month: moment.monthsShort(currentMonth - 1) + ' ' + currentYear,
            entries: [],
          });
        } else {
          this.monthlyLeaderboard.push({
            month: moment.monthsShort(currentMonth - 1) + ' ' + currentYear,
            entries: monthly.filter((x: FantasyLeaderboard) => {
              return (x.FantasyMonth as unknown as number) === currentMonth;
            }),
          });
        }
      }
      this.customOptions.startPosition =  this.monthlyLeaderboard.length - 1
    });
  }

  ngOnDestroy() {
    if (this.fantasySub) {
      this.fantasySub.unsubscribe();
    }
  }

  onCollapse(index:number, collapsed: boolean) {
    switch (index) {
      case 1:
        this.collapsedFirst = !collapsed;
        this.cdRef.detectChanges();
        break;
      case 2:
        this.collapsedSecond = !collapsed;
        this.cdRef.detectChanges();
        break;
      case 3:
        this.collapsedThird = !collapsed;
        this.cdRef.detectChanges();
        break;
    }
  }
  goToDetail(gameId: number) {
    this.router.navigate([`games/pickaplayer/${gameId}`]);
  }

  hasActivePlayer(game: FantasyGame) {
    return game.GamePlayers.some((g) => g.IsSelected);
  }
}
