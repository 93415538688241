<div class="d-none d-lg-block">
  <fgb-banner [title]="'pick.player.leaderboard.page.title' | translate"></fgb-banner>
</div>
<fgb-game-nav class="d-none d-lg-block"></fgb-game-nav>
<div class="container">
  <div class="pick-a-player-bar d-flex d-lg-none">
    <a [routerLink]="[backButtonLink]" class="btn d-flex align-items-center font-sans-bold px-0">
      <span class="arrow-right material-icons text-danger"> chevron_left </span>
      <span class="font-weight-bold font-size-14 text-danger">{{ 'pick.player.back.button' | translate }}</span>
    </a>
  </div>
  <div class="card mt-2 rounded border-disabled">
    <div class="nav d-flex align-items-center justify-content-center">
      <a class="nav-item w-25 text-center" [ngClass]="{ selected: showMonthly, corporate: isCorporate }" (click)="showMonthly = true">
        <label class="font-size-12 font-weight-bold text-center">{{ 'pick.player.leaderboard.monthly' | translate }}</label>
      </a>
      <a class="nav-item w-25 text-center" [ngClass]="{ selected: !showMonthly, corporate: isCorporate }" (click)="showMonthly = false">
        <label class="font-size-12 font-weight-bold text-center">{{ 'pick.player.leaderboard.yearly' | translate }}</label>
      </a>
    </div>
    <div class="leaderboard-nav" *ngIf="showMonthly; else yearly">
      <owl-carousel-o [options]="customOptions" #monthCarousel>
        <ng-template carouselSlide *ngFor="let item of monthlyLeaderboard; let i = index" [id]="i.toString()">
          <div class="py-1 bg-disabled leaderboard-date-toggle">
            <h2 class="text-center font-sans-bold font-size-14 mb-0 month-position text-black">{{ item.month }}</h2>
          </div>
          <fgb-fantasy-leaderboard [fantasyEntries]="item.entries"></fgb-fantasy-leaderboard>
        </ng-template>
      </owl-carousel-o>
    </div>
    <ng-template #yearly>
      <div class="py-1 yearly-header bg-disabled">
        <h2 class="text-center font-sans-bold font-size-14 mb-0 month-position text-black">{{ year }}</h2>
      </div>
      <fgb-fantasy-leaderboard [fantasyEntries]="yearlyLeaderboards"></fgb-fantasy-leaderboard>
    </ng-template>
  </div>
</div>
