<fgb-banner [title]="'fantasy.page.banner' | translate"></fgb-banner>

<fgb-game-nav></fgb-game-nav>
<div class="container">
  <div class="d-lg-none d-block p-3 border border-disabled rounded my-3 font-size-14 font-sans-bold text-center">
    {{'fantasy.game.bonus.point' | translate}}
  </div>
</div>
<div class="container">
  <ng-container
    *ngIf="
      {
        pendingGames: pendingGames$ | async,
        currentGames: currentGames$ | async
      } as games;
      else noGames
    "
  >
    <ng-container *ngIf="!!games.currentGames.length || !!games.pendingGames.length; else noGames">
      <ng-container *ngIf="games.currentGames && !!games.currentGames.length">
        <div class="d-lg-block d-none">
          <div class="row fantasy-game">
            <div class="col-lg-6 col-12">
              <fgb-fantasy-game-panel
                [game]="games.currentGames[0]"
                (button)="goToDetail(games.currentGames[0].GameId)"
                [buttonState]="hasActivePlayer(games.currentGames[0]) ? 'player-link' : 'no-player-link'"
              ></fgb-fantasy-game-panel>
            </div>
            <div class="col-lg-6 col-12">
              <div *ngIf="games.currentGames[1]; else noGame">
                <fgb-fantasy-game-panel-next-game [game]="games.currentGames[1]"> </fgb-fantasy-game-panel-next-game>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block d-lg-none mobile-carousel">
          <owl-carousel-o [options]="customOptions2">
            <ng-template carouselSlide>
              <fgb-fantasy-game-panel
                [game]="games.currentGames[0]"
                (button)="goToDetail(games.currentGames[0].GameId)"
                [buttonState]="hasActivePlayer(games.currentGames[0]) ? 'player-link' : 'no-player-link'"
              ></fgb-fantasy-game-panel
            ></ng-template>
            <ng-template carouselSlide *ngIf="games.currentGames[1]">
              <fgb-fantasy-game-panel-next-game [game]="games.currentGames[1]"> </fgb-fantasy-game-panel-next-game>
            </ng-template>
          </owl-carousel-o>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noGame>
    <div class="no-game-panel card rounded d-flex align-items-center justify-content-center border-disabled">
      <h4 [ngClass]="isCorporate ? 'text-primary-corporate' : 'text-primary'">{{ 'pick.player.game.panel.placeholder' | translate }}</h4>
    </div>
  </ng-template>

  <div class="row">
    <div class="col-lg-6 col-12">
      <h5 class="fw-bold mt-4 mb-3 font-size-22">{{ 'pick.player.history.title' | translate }}</h5>
      <fgb-fantasy-history-list> </fgb-fantasy-history-list>
    </div>

    <div class="col-lg-6 col-12">
      <h5 class="fw-bold mt-4 mb-3 font-size-22">{{ 'pick.player.leaderboard.title' | translate }}</h5>
      <div class="card leaderboard-card rounded border-disabled">
        <div class="nav d-flex align-items-center justify-content-center">
          <a class="nav-item w-25 text-center" [ngClass]="{ selected: showMonthly, 'corporate' : isCorporate }" (click)="showMonthly = true">
            <label class="font-size-12 fw-bold text-center">{{ 'pick.player.leaderboard.monthly' | translate }}</label>
          </a>
          <a class="nav-item w-25 text-center" [ngClass]="{ selected: !showMonthly, 'corporate' : isCorporate }" (click)="showMonthly = false">
            <label class="font-size-12 fw-bold text-center">{{ 'pick.player.leaderboard.yearly' | translate }}</label>
          </a>
        </div>
        <div class="leaderboard-nav" *ngIf="showMonthly; else yearly">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of monthlyLeaderboard">
              <div class="py-1 bg-disabled leaderboard-date-toggle">
                <h2 class="text-center fw-bold font-size-12 mb-0 month-position">{{ item.month }}</h2>
              </div>
              <fgb-fantasy-leaderboard [fantasyEntries]="item.entries"></fgb-fantasy-leaderboard>
            </ng-template>
          </owl-carousel-o>
        </div>
        <ng-template #yearly>
          <div class="py-1 bg-disabled mt-1 year-toggle">
            <h2 class="text-center fw-bold font-size-12 mb-0 month-position">{{ year }}</h2>
          </div>
          <fgb-fantasy-leaderboard [fantasyEntries]="yearlyLeaderboards"></fgb-fantasy-leaderboard>
        </ng-template>
        <div class="leaderboard-button text-center w-100">
          <a class="fw-bold text-info font-size-14" [routerLink]="['/games/pickaplayer/fantasyleaderboard']">{{
            'pick.player.view.full.leaderboard' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>

  <!--Placeholder Information-->
  <ng-template #noGames>
    <div class="no-items w-100 text-center">
      <div class="icon material-icons text-grey">supervised_user_circle</div>
      <h5
        class="text-primary text-center font-weight-bold text-uppercase"
        [ngClass]="isCorporate ? 'text-secondary-corporate corporate-font' : 'text-primary default-font'"
      >
        {{ 'pick.player.placeholder.title' | translate }}
      </h5>
      <p class="text-center font-size-12 text-black">{{ 'pick.player.placeholder.description' | translate }}</p>
    </div>
  </ng-template>

 <!--Rules-->
 <h5 class="game-title text-start mt-4">{{ 'pick.player.rules.title' | translate }}</h5>

 <div class="col-12 games-rules-container mt-lg-3">
   <div class="row m-0">
     <div ngbAccordion #a="ngbAccordion" [destroyOnHide]="false" class="mt-3 w-100 mt-lg-0">
       <!--Q1-->
       <div class="border-0" ngbAccordionItem [collapsed]="true">
         <div ngbAccordionHeader>
           <div class="row border p-1 border-bottom-0" [ngClass]="{ 'radius-first-collapsed': collapsedFirst, 'radius-first-uncollapsed': !collapsedFirst, 'bg-primary' : !isCorporate && collapsedFirst, 'bg-primary-corporate' : isCorporate && collapsedFirst}">
             <div class="col-11 faq-question-text fw-bold ps-0 pt-1" (click)="a.toggle('ngb-panel-0')">
               <div class="rule-question-titles">
                 {{ 'pick.player.rules3.title' | translate }}
               </div>
             </div>
             <div class="col-1" [(ngModel)]="collapsedFirst">
               <button class="btn button-collapse p-0" type="button" (click)="onCollapse(1,collapsedFirst)"
                 attr.aria-label="{{ 'pick.player.see.details' | translate }}" ngbAccordionButton></button>
             </div>
           </div>
         </div>
         <div ngbAccordionCollapse class="border-muted border-0">
           <div ngbAccordionBody class="row p-0">
             <div class="content col-12 p-0">
               <div class="border fantasy text-grey">
                 <div class="font-size-14 rule-description text-black"
                   [innerHTML]="'pick.player.rules3.description' | translate | markdownTranslate"></div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     <div ngbAccordion #b="ngbAccordion" [destroyOnHide]="false" class="w-100 mt-lg-0">
       <!--Q2-->
       <div class="border-0" ngbAccordionItem [collapsed]="true">
         <div ngbAccordionHeader>
           <div class="row border p-1 border-bottom-0" [ngClass]="{ 'radius-second-collapsed': collapsedSecond, 'radius-second-uncollapsed': !collapsedSecond, 'bg-primary' : !isCorporate && collapsedSecond, 'bg-primary-corporate' : isCorporate && collapsedSecond }">
             <div class="col-11 faq-question-text fw-bold ps-0 pt-1" (click)="b.toggle('ngb-panel-0')">
               <div class="rule-question-titles">
                 {{ 'pick.player.rules2.title' | translate }}
               </div>
             </div>
             <div class="col-1" [(ngModel)]="collapsedSecond">
               <button class="btn button-collapse p-0" type="button" (click)="onCollapse(2,collapsedSecond)"
                 attr.aria-label="{{ 'pick.player.see.details' | translate }}" ngbAccordionButton></button>
             </div>
           </div>
         </div>
         <div ngbAccordionCollapse class="border-muted">
           <div ngbAccordionBody class="row p-0">
             <div class="content col-12 p-0">
               <div class="border fantasy text-grey">
                 <div class="font-size-14 rule-description text-black"
                   [innerHTML]="'pick.player.rules2.description' | translate | markdownTranslate"></div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     <!-- Third Accordion -->
     <div ngbAccordion #c="ngbAccordion" [destroyOnHide]="false" class="w-100 mt-lg-0">
       <!-- Q3 -->
       <div class="border-0" ngbAccordionItem [collapsed]="true">
         <div ngbAccordionHeader>
           <div class="row border p-1"
             [ngClass]="{ 'radius-third-collapsed': collapsedThird, 'radius-third-uncollapsed': !collapsedThird, 'bg-primary' : !isCorporate && collapsedThird, 'bg-primary-corporate' : isCorporate && collapsedThird }">
             <div class="col-11 faq-question-text fw-bold ps-0 pt-1" (click)="c.toggle('ngb-panel-0')">
               <div class="rule-question-titles">
                {{ 'pick.player.rules1.title' | translate }}
               </div>
             </div>
             <div class="col-1"  [(ngModel)]="collapsedThird">
               <button class="btn button-collapse p-0" type="button" (click)="onCollapse(3,collapsedThird)"
                 attr.aria-label="{{ 'pick.player.see.details' | translate }}" ngbAccordionButton></button>
             </div>
           </div>
         </div>
         <div ngbAccordionCollapse class="border-muted">
           <div ngbAccordionBody class="row p-0">
             <div class="content col-12 p-0">
               <div class="card card-body fantasy text-grey">
                 <div class="font-size-14 rule-description text-black"
                   [innerHTML]="'pick.player.rules1.description' | translate | markdownTranslate"></div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
</div>
